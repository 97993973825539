import React from 'react'

import Layout from 'gatsby-theme-blog/src/components/layout'
import SEO from 'gatsby-theme-blog/src/components/seo'
import Footer from 'gatsby-theme-blog/src/components/home-footer'
import { graphql } from 'gatsby'
import { Themed } from 'theme-ui'

const PrivacyPolicy = ({ location, data }) => (
  <Layout location={location} title={data.site.siteMetadata.title}>
    <main>
      <SEO title="プライバシーポリシー" description="privacy policy" />
      <Themed.h1>プライバシーポリシー</Themed.h1>
      <Themed.h2>個人情報の保護について</Themed.h2>
      <Themed.p>
        「https://hakasenote.hnishi.com/」（以下、当サイト）を閲覧される方は、以下に記載する諸条件に同意したものとみなします。
      </Themed.p>
      <Themed.h2>個人情報の収集について</Themed.h2>
      <Themed.p>
        閲覧者は匿名のままで、当サイトを自由に閲覧する事ができます。
        <br />
        お問合せ等、場合によっては、閲覧者の氏名やメールアドレスなどの個人情報の開示をお願いする事があります。
        しかし、閲覧者の個人情報を閲覧者の許可なく、当サイトから第三者へ開示・共有する事はありません。
      </Themed.p>
      <Themed.h2>Google の広告配信について</Themed.h2>
      <Themed.p>
        当サイトは Google 及び Google
        のパートナーウェブサイト（第三者配信事業者）の提供する広告を設置しております。その広告配信には
        Cookie
        を使用し、当サイトや他のサイトのアクセス情報に基づいて広告を配信します。
        <br />
        Google は広告 Cookie
        を使用することにより、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて、Google
        やそのパートナーが適切な広告をユーザーに表示します。
        <br />
        閲覧者は、Googleアカウントの
        <Themed.a href="https://adssettings.google.com/authenticated">
          広告設定ページ
        </Themed.a>
        で、パーソナライズ広告の掲載に使用される Cookie を無効にできます。また
        <Themed.a href="http://www.aboutads.info/choices/">
          www.aboutads.info
        </Themed.a>
        にアクセスして、パーソナライズ広告の掲載に使用される第三者配信事業者の
        Cookie を無効にできます。
        <br />
        その他、Google の広告における Cookie
        の取り扱いについての詳細については、
        <Themed.a href="https://policies.google.com/technologies/ads?hl=ja">
          Google のポリシーと規約ページ
        </Themed.a>
        をご覧ください。
      </Themed.p>
      <Themed.h2>Amazon の広告配信について</Themed.h2>
      <Themed.p>
        hnishi は、Amazon.co.jp
        を宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazon
        アソシエイト・プログラムの参加者です。
      </Themed.p>
    </main>
    <Footer socialLinks={data.site.siteMetadata.social} />
  </Layout>
)

export default PrivacyPolicy

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
  }
`
